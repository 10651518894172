import React from "react";
import { BaseLayout, OuterContainer } from "../components/Layout";
import faqContent from "../constants/faqContent";
import { FAQSection } from "../components/Sections";

const faq = () => {
  return (
    <BaseLayout page="faq" leftBox="contact" rightBox="services">
      <OuterContainer>
        <FAQSection faqContent={faqContent} />
      </OuterContainer>
    </BaseLayout>
  );
};

export default faq;
