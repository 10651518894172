const faqContent = [
  {
    question: "Can you give me a quote from photographs?",
    answer:
      "Yes but we may need to revise this when we visit the property if there are unforeseen issues.",
  },
  {
    question:
      "Are you able to do a full property clean with the client at home?",
    answer:
      "Yes, although it is usually less stressful for the client, and more straightforward for us, if they are out. ",
  },
  {
    question: "Can you help hoarders?",
    answer:
      "We assess on a case-to-case basis. Its important for the client to be at a stage where they are ready to receive the help and engage with the process.",
  },
  {
    question: "Do you only work with social services?",
    answer:
      "Most of our referrals come from social services or similar local authority teams. We do work with self-funders and are happy to discuss your needs and budget on an individual basis",
  },
  {
    question: "Can you help with decorating and DIY?",
    answer: "No, we are not insured to carry out these tasks.",
  },
  {
    question: "Why do you mainly work in pairs?",
    answer:
      "We have found that working in pairs is the most efficient approach, both for us and our clients. It means that you get double the work in half the time, or that tasks can be divided.",
  },
  {
    question:
      "I’ve had a big one-off clean. Am I able to call you for help with general cleaning on an ad hoc basis?",
    answer:
      "Absolutely! We would be very happy to come back and do a ‘top up’ clean for you.",
  },
  {
    question:
      "If someone needs a deep clean does it all have to happen in one or two days, or can it be spread over a number of weeks?",
    answer:
      "We are happy to work in whichever way is best for the client: people often prefer to have their clean carried out in shorter sessions of 2 - 3 hours at a time. This can certainly be spread over several weeks if that would be more manageable for the client.",
  },
  {
    question: "How much do you charge for an hour's cleaning?",
    answer:
      "The majority of our work is charged at £25 per hour (per worker). Some clients meet the criteria for our complex needs rate, which is £35 per hour.",
  },
  {
    question: "What happens if I want to end my cleaning service?",
    answer:
      "For self funders we require 2 weeks notice, for clients under Direct Payments we require one month's notice.",
  },
];

export default faqContent;
